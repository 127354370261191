import AdminLayout from '@/layout/admin-layout.vue'
import RouteViewComponent from "@/router/route-view.vue";

export default
{
  name: 'admin',
  path: '/admin',
  component: AdminLayout,
  redirect: 'admin-impostos',
  children: [
    {
      name: 'admin-impostos',
      path: 'impostos',
      component: () => import('@/pages/painel/impostos/Impostos.vue'),
      meta: {
        name: 'Admin Impostos',
        admin: true,
      },
    },
    {
      name: 'admin-impostos-new',
      path: 'impostos-new',
      component: () => import('@/pages/painel/impostos/ImpostosNew.vue'),
      meta: {
        name: 'Admin Impostos',
        admin: true,
      },
    },
    {
      name: 'admin-impostos-update',
      path: 'impostos-update',
      props: true,
      component: () => import('@/pages/painel/impostos/ImpostosUpdate.vue'),
      meta: {
        name: 'Admin Impostos',
        admin: true,
      },
    },
    {
      name: 'admin-documentos',
      path: 'documentos',
      component: () => import('@/pages/painel/documentos/Documentos.vue'),
      meta: {
        name: 'Admin Documentos',
        admin: true,
      },
    },
    {
      name: 'admin-documentos-new',
      path: 'documentos-new',
      component: () => import('@/pages/painel/documentos/DocumentosNew.vue'),
      meta: {
        name: 'Admin Novo Documento',
        admin: true,
      },
    },
    {
      name: 'admin-faturas',
      path: 'honorarios',
      component: () => import('@/pages/painel/faturas/Faturas.vue'),
      meta: {
        name: 'Admin Honorários',
        admin: true,
      },
    },
    {
      name: 'admin-faturas-new',
      path: 'honorarios-new',
      component: () => import('@/pages/painel/faturas/FaturasNew.vue'),
      meta: {
        name: 'Admin Honorários',
        admin: true,
      },
    },
    {
      name: 'admin-certidoes',
      path: 'certidoes',
      component: () => import('@/pages/painel/certidoes/Certidoes.vue'),
      meta: {
        name: 'Admin Certidões',
        admin: true,
      },
    },
    {
      name: 'admin-certidoes-new',
      path: 'certidoes-new',
      component: () => import('@/pages/painel/certidoes/CertidoesNew.vue'),
      meta: {
        name: 'Admin Certidões',
        admin: true,
      },
    },
    {
      name: 'admin-notas-fiscais',
      path: 'notas-fiscais',
      props: true,
      component: () => import('@/pages/painel/breve/EmBreve.vue'),
      meta: {
        name: 'Admin Notas Fiscais',
        admin: true,
      },
    },
    {
      name: 'admin-contabilidade',
      path: 'contabilidade',
      component: () => import('@/pages/painel/contabilidade/Contabilidade.vue'),
      meta: {
        name: 'Admin Contabilidade',
      },
    },
    {
      name: 'admin-dados-cadastrais',
      path: 'dados-cadastrais',
      component: () => import('@/pages/painel/dados/Dados.vue'),
      meta: {
        name: 'Admin Dados Cadastrais',
      },
    },
    {
      name: 'admin-contabilidade-new',
      path: 'contabilidade-new',
      component: () => import('@/pages/painel/contabilidade/ContabilidadeNew.vue'),
      meta: {
        name: 'Admin Contabilidade',
      },
    },
    {
      name: 'admin-certificado-digital',
      path: 'certificado-digital',
      component: () => import('@/pages/painel/certificados/Certificados.vue'),
      meta: {
        name: 'Admin Certificado Digital',
      },
    },
    {
      name: 'admin-certificado-digital-new',
      path: 'certificado-digital-new',
      component: () => import('@/pages/painel/certificados/CertificadosNew.vue'),
      meta: {
        name: 'Admin Certificado Digital',
      },
    },
    {
      name: 'admin-movimentacoes',
      path: 'movimentacoes',
      component: () => import('@/pages/painel/movimentacoes/Movimentacoes.vue'),
      meta: {
        name: 'Admin Movimentações',
      },
    },
    {
      name: 'admin-movimentacoes-new',
      path: 'movimentacoes-new',
      component: () => import('@/pages/painel/movimentacoes/MovimentacoesNewForm.vue'),
      meta: {
        name: 'Admin enviar movimentações',
      },
    },
    {
      name: 'admin-outras-solicitacoes',
      path: 'outras-solicitacoes',
      component: () => import('@/pages/painel/breve/EmBreve.vue'),
      meta: {
        name: 'Admin Outras Solicitações',
      },
    },
    {
      name: 'admin-clientes',
      path: 'clientes',
      component: () => import('@/pages/painel/clientes/Clientes.vue'),
      meta: {
        name: 'Admin Clientes',
      },
    },
    {
      name: 'admin-clientes-new',
      path: 'clientes-new',
      component: () => import('@/pages/painel/clientes/ClientesNew.vue'),
      meta: {
        name: 'Admin Novo Cliente',
      },
    },
    {
      name: 'admin-clientes-update-pass',
      path: 'clientes-update-pass',
      props: true,
      component: () => import('@/pages/painel/clientes/ClientesTrocaSenha.vue'),
      meta: {
        name: 'Admin Trocar Senha',
        admin: true,
      },
    },
    {
      name: 'admin-clientes-update-logo',
      path: 'clientes-update-logo',
      props: true,
      component: () => import('@/pages/painel/clientes/ClientesTrocaFoto.vue'),
      meta: {
        name: 'Admin Trocar Logo',
        admin: true,
      },
    },
    {
      name: 'admin-clientes-update',
      path: 'clientes-update',
      props: true,
      component: () => import('@/pages/painel/clientes/ClientesUpdate.vue'),
      meta: {
        name: 'Admin Atualizar Cliente',
        admin: true,
      },
    },
  ]
}
export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'admin-documentos',
      displayName: 'Documentos',
      meta: {
        icon: 'folder',
        extraClass: 'material-icons-outlined',
        size: '28px',
        color: "#4d4e4b"
      },
    },
    {
      name: 'admin-certidoes',
      displayName: 'Certidões',
      meta: {
        icon: 'fact_check',
        extraClass: 'material-icons-outlined',
        size: '28px',
        color: "#4d4e4b"
      },
    },
    {
      name: 'admin-contabilidade',
      displayName: 'Contabilidade',
      meta: {
        icon: 'auto_stories',
        extraClass: 'material-icons-outlined',
        size: '28px',
        color: "#4d4e4b"
      },
    },
    {
      name: 'admin-movimentacoes',
      displayName: 'Movimentações',
      meta: {
        icon: 'import_export',
        extraClass: 'material-icons-outlined',
        size: '28px',
        color: "#4d4e4b"
      },
    },
    {
      name: 'admin-faturas',
      displayName: 'Honorários',
      meta: {
        icon: 'receipt_long',
        extraClass: 'material-icons-outlined',
        size: '28px',
        color: "#4d4e4b"
      },
    },
    {
      name: 'admin-impostos',
      displayName: 'Impostos',
      meta: {
        icon: 'calculate',
        extraClass: 'material-icons-outlined',
        size: '28px',
        color: "#4d4e4b"
      },
    },
    {
      name: 'admin-dados-cadastrais',
      displayName: 'Dados Cadastrais',
      meta: {
        icon: 'store',
        extraClass: 'material-icons-outlined',
        size: '28px',
        color: "#4d4e4b"
      },
    },
    {
      name: 'admin-certificado-digital',
      displayName: 'Certificado Digital',
      meta: {
        icon: 'verified_user',
        extraClass: 'material-icons-outlined',
        size: '28px',
        color: "#4d4e4b"
      },
    },
  ],
}

import Swal from 'sweetalert2'
import {COLOR_THEMES} from "@/services/vuestic-ui/themes";

export function GenericError(){
    Swal.fire({
        icon: 'error',
        title: 'Oops... Algo deu errado',
        html: 'Sentimos muito pelo inconveniente.<br>Analisaremos esse erro o mais breve possível',
        backdrop: 'rgba(32, 139, 165,0.9)',
        showClass: {
            popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
        },
        timer: 8000,
        timerProgressBar: true,
        showCloseButton: true,
        allowOutsideClick: true,
        allowEscapeKey: true,
        allowEnterKey: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
    return false;
}
export function showError(m: string){
    Swal.fire({
        title: m,
        toast: true,
        background: COLOR_THEMES[0].colors.danger,
        color: '#fff',
        timer: 8000,
        showConfirmButton: false,
        timerProgressBar: true,
        showCloseButton: true,
        allowOutsideClick: true,
        allowEscapeKey: true,
        allowEnterKey: true,
        position: 'top-end',
        showClass: {
            popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
        },
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })
}
import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'

import DemoRoutes from './DemoRoutes'
import {Auth} from "@/controllers/Auth";
import AuthRoutes from "@/router/AuthRoutes";
import PainelRoutes from "@/router/PainelRoutes";
import AppLayout from "@/layout/demo-layout.vue";
import Page404Layout from "@/layout/page-404-layout.vue";
import AdminRoutes from "@/router/AdminRoutes";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/:catchAll(.*)",
        redirect: {name: 'dashboard'},
    },
    AuthRoutes,
    PainelRoutes,
    AdminRoutes,
    DemoRoutes,
    {
        path: '/',
        name: 'others',
        component: AppLayout,
        children: [
            {
                name: 'redirect',
                path: 'redirect',
                props: true,
                component: () => import('@/components/global/Redirect.vue'),
                children: []
            },
            {
                path: '/404',
                component: Page404Layout,
                children: [
                    {
                        name: 'not-found-advanced',
                        path: 'not-found-advanced',
                        component: () => import('@/pages/demo/404-pages/VaPageNotFoundSearch.vue'),
                    },
                    {
                        name: 'not-found-simple',
                        path: 'not-found-simple',
                        component: () => import('@/pages/demo/404-pages/VaPageNotFoundSimple.vue'),
                    },
                    {
                        name: 'not-found-custom',
                        path: 'not-found-custom',
                        component: () => import('@/pages/demo/404-pages/VaPageNotFoundCustom.vue'),
                    },
                    {
                        name: 'not-found-large-text',
                        path: '/pages/not-found-large-text',
                        component: () => import('@/pages/demo/404-pages/VaPageNotFoundLargeText.vue'),
                    },
                ],
            },
        ]
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    //  mode: process.env.VUE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
    routes
})

router.beforeEach(async (to, from, next) => {
    const $Auth = new Auth()
    if(!$Auth.logado) {await $Auth.setLogin()}
    const isAdmin = $Auth.isAdmin();
    const homeName = isAdmin ? 'admin-certidoes' : 'dashboard';
    if ($Auth.logado && to.meta?.perm && !$Auth.checkPerm(to.meta.perm)) {
        $Auth.sair();
    }
    if (['login', 'signup'].includes(to.name?.toString() ?? '')) {
        if($Auth.logado){
            next({name: homeName})
        } else {
            next()
        }
    } else if (!$Auth.logado) {
        next({name: 'login'})
    } else if(isAdmin && to.name && !to.name.toString().includes("admin")){
        next({name: homeName})
    } else {
        next()
    }
})

export default router

<template>
  <div id="loader">
    <div class="Spinner">
      <semipolar-spinner
        :animation-duration="1200"
        :size="100"
        color="white"
      />
    </div>
  </div>
  <router-view/>
</template

>
<script>
import { SemipolarSpinner } from 'epic-spinners'

export default {
  components: {
    SemipolarSpinner
  }
}
</script>
<style lang="scss">
@import '~@/sass/main.scss';
.swal2-container.swal2-top-end.swal2-backdrop-show {
  z-index: 999999999 !important;
}
#app {
  font-family: 'Source Sans Pro', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
body {
  margin: 0;
  background: var(--va-background);
}
.Spinner {
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  margin: 0 auto;
  margin-top: 40vh;
}
#loader {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 99999;
  top: 0;
  background: rgba(12, 57, 52, 0.9);
  display:none;
}
.swal2-popup {
  font-family: var(--va-font-family) !important;
}

</style>

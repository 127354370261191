export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'Dashboard',
      meta: {
        icon: 'vuestic-iconset-dashboard',
      },
    },
    {
      name: 'documentos',
      displayName: 'Documentos',
      meta: {
        icon: 'folder',
        extraClass: 'material-icons-outlined',
        size: '28px',
        color: "#4d4e4b"
      },
    },
    {
      name: 'certidoes',
      displayName: 'Certidoes',
      meta: {
        icon: 'fact_check',
        extraClass: 'material-icons-outlined',
        size: '28px',
        color: "#4d4e4b"
      },
    },
    {
      name: 'contabilidade',
      displayName: 'Contabilidade',
      meta: {
        icon: 'auto_stories',
        extraClass: 'material-icons-outlined',
        size: '28px',
        color: "#4d4e4b"
      },
    },
    {
      name: 'movimentacoes',
      displayName: 'Movimentações',
      meta: {
        icon: 'import_export',
        extraClass: 'material-icons-outlined',
        size: '28px',
        color: "#4d4e4b"
      },
    },
    {
      name: 'faturas',
      displayName: 'Honorários',
      meta: {
        icon: 'receipt_long',
        extraClass: 'material-icons-outlined',
        size: '28px',
        color: "#4d4e4b"
      },
    },
    {
      name: 'impostos',
      displayName: 'Impostos',
      meta: {
        icon: 'calculate',
        extraClass: 'material-icons-outlined',
        size: '28px',
        color: "#4d4e4b"
      },
    },
    {
      name: 'notas-fiscais',
      displayName: 'Notas Fiscais',
      disabled: true,
      children: [
        {
          name: 'redirect',
          displayName: 'Comércio',
          params: {
            url: 'https://exemplo.com.br/'
          }
        },
        {
          name: 'redirect',
          displayName: 'Serviços',
          params: {
            url: 'url_servicos'
          }
        },
      ],
      meta: {
        icon: 'receipt',
        extraClass: 'material-icons-outlined',
        size: '28px',
        color: "#4d4e4b"
      },
    },
    {
      name: 'certificado-digital',
      displayName: 'Certificado Digital',
      meta: {
        icon: 'verified_user',
        extraClass: 'material-icons-outlined',
        size: '28px',
        color: "#4d4e4b"
      },
    },
    {
      name: 'dados-cadastrais',
      displayName: 'Minha Empresa',
      meta: {
        icon: 'store',
        extraClass: 'material-icons-outlined',
        size: '28px',
        color: "#4d4e4b"
      },
    },
    {
      name: 'outras-solicitacoes',
      displayName: 'Outras solicitações',
      meta: {
        icon: 'queue',
        extraClass: 'material-icons-outlined',
        size: '28px',
        color: "#4d4e4b"
      },
    },
  ],
}

import RouteViewComponent from '@/router/route-view.vue'
import AppLayout from '@/layout/demo-layout.vue'
import UIRoutes from "./UIRoutes";

export default {
  path: '/demo',
  name: 'demo',
  redirect: 'demo-dashboard',
  component: AppLayout,
  children: [
    {
      name: 'demo-dashboard',
      path: 'dashboard',
      component: () => import('@/pages/demo/admin/dashboard/Dashboard.vue'),
    },
    {
      name: 'forms',
      path: 'forms',
      component: RouteViewComponent,
      children: [
        {
          name: 'form-elements',
          path: 'form-elements',
          component: () => import('@/pages/demo/admin/forms/form-elements/FormElements.vue'),
          meta: {
            wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/inputs',
          },
        },
      ],
    },
    {
      name: 'tables',
      path: 'tables',
      component: RouteViewComponent,
      children: [
        {
          name: 'markup',
          path: 'markup',
          component: () => import('@/pages/demo/admin/tables/markup-tables/MarkupTables.vue'),
          meta: {
            wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Tables',
          },
        },
        {
          name: 'data',
          path: 'data',
          component: () => import('@/pages/demo/admin/tables/data-tables/DataTables.vue'),
          meta: {
            wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Tables',
          },
        },
      ],
    },
    {
      name: 'pages',
      path: 'pages',
      component: RouteViewComponent,
      children: [
        {
          name: '404-pages',
          path: '404-pages',
          component: () => import('@/pages/demo/admin/pages/404PagesPage.vue'),
        },
        {
          name: 'faq',
          path: 'faq',
          component: () => import('@/pages/demo/admin/pages/FaqPage.vue'),
        },
      ],
    },
    UIRoutes,
  ]
}
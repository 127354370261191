import {FetchWs} from "@/functions/FetchWs";

export class Auth {
    private static _instance?: Auth;
    public JWT?: string
    public logado = false
    public props: any = {}
    constructor() {
        if (Auth._instance) {
            return Auth._instance
        }
        Auth._instance = this;
    }
    async setLogin(jwt: string|undefined = undefined){
        if (!jwt && localStorage.getItem('JWT_TOKEN')){
            jwt = localStorage.getItem('JWT_TOKEN') ?? undefined
        }
        //@todo check jwt
        if(jwt){
            localStorage.setItem('JWT_TOKEN', jwt);
            this.logado = true
            this.JWT = jwt
            this.props = this.parseJwt(jwt);
            await this.setPerms()
        }
    }
    checkPerm(key: any){
        if (!key) {return true;}
        return this.props?.perms?.includes(key);
    }
    getProp(key: string){
        if (!key) {return false;}
        return this.props[key];
    }
    isAdmin(){
        return this.getProp('grp_id') === '2';
    }
    sair() {
        localStorage.removeItem('JWT_TOKEN');
        setTimeout(() => document.location.reload(),0);
    }
    parseJwt (token: string) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const encodedPayload: string[] = atob(base64).split('');
        let jsonPayload = '';
        if (typeof encodedPayload !== undefined && encodedPayload) {
            jsonPayload = decodeURIComponent((encodedPayload ? encodedPayload : []).map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''))
        }

        return JSON.parse(jsonPayload);
    }
    async setPerms() {
        const response = await FetchWs('user/get-all-perms/' + this.props.id);
        if(response && response.data && Array.isArray(response.data)){
            this.props.perms = response.data
        }
    }
}

import PainelLayout from '@/layout/painel-layout.vue'
import RouteViewComponent from "@/router/route-view.vue";

export default
{
  name: 'painel',
  path: '/',
  component: PainelLayout,
  redirect: 'dashboard',
  children: [
    {
      name: 'dashboard',
      path: 'dashboard',
      component: () => import('@/pages/painel/dashboard/Dashboard.vue'),
      meta: {
        name: 'Dashboard',
      },
    },
    {
      name: 'impostos',
      path: 'impostos',
      component: () => import('@/pages/painel/impostos/Impostos.vue'),
      meta: {
        name: 'Meus Impostos',
      },
    },
    {
      name: 'documentos',
      path: 'documentos',
      component: () => import('@/pages/painel/documentos/Documentos.vue'),
      meta: {
        name: 'Meus Documentos',
      },
    },
    {
      name: 'faturas',
      path: 'honorarios',
      component: () => import('@/pages/painel/faturas/Faturas.vue'),
      meta: {
        name: 'Meus Honorários',
      },
    },
    {
      name: 'certidoes',
      path: 'certidoes',
      component: () => import('@/pages/painel/certidoes/Certidoes.vue'),
      meta: {
        name: 'Minhas Certidões',
      },
    },
    {
      name: 'notas-fiscais',
      path: 'notas-fiscais',
      props: true,
      component: () => import('@/pages/painel/breve/EmBreve.vue'),
    },
    {
      name: 'contabilidade',
      path: 'contabilidade',
      component: () => import('@/pages/painel/contabilidade/Contabilidade.vue'),
      meta: {
        name: 'Minha Contabilidade',
      },
    },
    {
      name: 'certificado-digital',
      path: 'certificado-digital',
      component: () => import('@/pages/painel/certificados/Certificados.vue'),
      meta: {
        name: 'Meu Certificado Digital',
      },
    },
    {
      name: 'movimentacoes',
      path: 'movimentacoes',
      component: () => import('@/pages/painel/movimentacoes/Movimentacoes.vue'),
      meta: {
        name: 'Minhas movimentações',
      },
    },
    {
      name: 'dados-cadastrais',
      path: 'dados-cadastrais',
      component: () => import('@/pages/painel/dados/Dados.vue'),
      meta: {
        name: 'Dados Cadastrais',
      },
    },
    {
      name: 'dados-cadastrais-atualizar-empresa',
      path: 'dados-cadastrais-atualizar-empresa',
      component: () => import('@/pages/painel/dados/DadosAtualizarEmpresa.vue'),
      meta: {
        name: 'Atualizar Dados Cadastrais',
      },
    },
    {
      name: 'dados-cadastrais-atualizar-socios',
      path: 'dados-cadastrais-atualizar-socios',
      component: () => import('@/pages/painel/dados/DadosAtualizarSocios.vue'),
      meta: {
        name: 'Atualizar Sócios',
      },
    },
    {
      name: 'dados-cadastrais-atualizar-outros',
      path: 'dados-cadastrais-atualizar-outros',
      component: () => import('@/pages/painel/dados/DadosAtualizarOutros.vue'),
      meta: {
        name: 'Atualizar Outros',
      },
    },
    {
      name: 'movimentacoes-new',
      path: 'movimentacoes-new',
      component: () => import('@/pages/painel/movimentacoes/MovimentacoesNewForm.vue'),
      meta: {
        name: 'Enviar movimentações',
      },
    },
    {
      name: 'outras-solicitacoes',
      path: 'outras-solicitacoes',
      component: () => import('@/pages/painel/solicitacoes/SolicitacoesNew.vue'),
      meta: {
        name: 'Enviar solicitação',
      },
    },
    {
      name: 'update-pass',
      path: 'update-pass',
      props: true,
      component: () => import('@/pages/painel/clientes/ClientesTrocaSenha.vue'),
      meta: {
        name: 'Trocar Senha'
      },
    },
  ]
}
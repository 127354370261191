<template>
  <div class="app-layout__navbar">
    <va-navbar>
      <template v-slot:left>
        <div class="left">
          <va-icon-menu-collapsed
            @click="$emit('changeSideBar')"
            :class="{ 'x-flip': isSidebarMinimized }"
            class="va-navbar__item"
            :color="colors.primary"
          />
          <router-link to="/">
            <img src="/img/logo.png" width="160">
          </router-link>
        </div>
      </template>
      <template #center>

        <va-select
          v-if="$Auth.isAdmin()"
          :label="'Selecione um cliente'"
          v-model="clienteId"
          searchable
          color="primary"
          text-by="description"
          track-by="id"
          value-by="id"
          width="200%"
          :options="empresas"
        />
      </template>
      <template #right>
        <va-button-group>
          <va-button v-if="$Auth.isAdmin()" @click="$router.push({name:'admin-clientes'})">Gerenciar Clientes</va-button>
          <va-button v-else @click="$router.push({name:'update-pass', params: {id: $Auth.getProp('id')}})">Trocar Senha</va-button>
          <va-button @click="$Auth.sair()" color="secondary">Sair</va-button>
        </va-button-group>
      </template>
    </va-navbar>
  </div>
</template>

<script>
import { useColors } from 'vuestic-ui'
import { computed } from 'vue'
import VaIconMenuCollapsed from '@/components/icons/VaIconMenuCollapsed'
import {FetchWs} from "@/functions/FetchWs";

export default {
  components: { VaIconMenuCollapsed },
  props: {
    isSidebarMinimized: { type: Boolean, default: false },
  },
  setup() {
    const { getColors } = useColors()
    const colors = computed(() => getColors() )

    return {
      colors
    }
  },
  methods:{
    setEmpresaAtual(clienteId){
      if (parseInt(clienteId) !== this. getEmpresaAtual()){
        localStorage.setItem('ADMIN_EMPRESA_ATUAL', clienteId);
        let r = this.$router.resolve({
          name: this.$route.name, // put your route information in
          params: this.$route.params, // put your route information in
          query: this.$route.query // put your route information in
        });
        window.location.assign(r.href)
      }
    },
    getEmpresaAtual(){
      return parseInt(localStorage.getItem('ADMIN_EMPRESA_ATUAL') ?? '0');
    },
    async getEmpresas() {
      const response = await FetchWs('user/get-all');
      if (response && response.data && Array.isArray(response.data)) {
        this.empresas = response.data.map((e)=>{
          return {id: e.usu_id, description:e.usu_name}
        })
        this.clienteId = this.getEmpresaAtual()
      }
    }
  },
  data() {
    if(this.$Auth.isAdmin()) {this.getEmpresas()}
    return{
      clienteId: 0,
      empresas: []
    }
  },
  watch: {
    clienteId: function (clienteId) {
      if(clienteId){
        this.setEmpresaAtual(clienteId)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .va-navbar {
    box-shadow: var(--va-box-shadow);
    z-index: 2;
    &__center {
      @media screen and (max-width: 1200px) {
        .app-navbar__github-button {
          display: none;
        }
      }
      @media screen and (max-width: 950px) {
        .app-navbar__text {
          display: none;
        }
      }
    }

    @media screen and (max-width: 950px) {
      .left {
        width: 100%;
      }
      .app-navbar__actions {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .left {
    display: flex;
    align-items: center;
    & > * {
      margin-right: 1.5rem;
    }
    & > *:last-child {
      margin-right: 0;
    }
  }

  .x-flip {
    transform: scaleX(-100%);
  }

  .app-navbar__text > * {
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
</style>
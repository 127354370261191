import {Auth} from "@/controllers/Auth";
import {GenericError} from "@/functions/Error";
import {LoaderInit, LoaderStop} from "@/functions/Loader";
import {ProcessaResponse} from "@/functions/ProcessaResponse";

export async function FetchWs(uri: string, method = 'GET', body: any = {}, headers: any = {}) {
    LoaderInit();

    const $Auth = new Auth()
    if (!headers.Authorization) {
        if ($Auth.JWT) {
            headers.Authorization = `Bearer ${$Auth.JWT}`;
        }
    }

    headers = {...headers}

    const request: RequestInit = {
        method: method,
        credentials: 'include',
        headers: headers
    };

    if (method !== 'GET') {
        request.body = body;
    }

    const url = new URL(`${process.env.VUE_APP_URL_WS}/${uri}`);
    
    const force_empresa = localStorage.getItem('ADMIN_EMPRESA_ATUAL');

    if($Auth.checkPerm('adminForceEmpresa') && force_empresa){
        url.searchParams.set('force_empresa',force_empresa)
    }

    return await
        fetch(url.toString(), request)
        .then((r) => {
            if ([403].includes(r.status)) {
                $Auth.sair();
            }
            return r.json().then(json => ProcessaResponse(json));
        })
        .catch(GenericError)
        .finally(LoaderStop)
}

export async function FetchExternal(url: string, method = 'GET', body: any = {}, headers: any = {}) {
    LoaderInit();

    const request: RequestInit = {
        method: method,
        headers: headers
    };
    if (method !== 'GET') {
        request.body = body;
    }
    return await
        fetch(url.toString(), request)
            .then((r) => {
                return r.json().then(json => ProcessaResponse(json));
            })
            .catch(GenericError)
            .finally(LoaderStop)
}

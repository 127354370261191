import Swal from 'sweetalert2'
import {COLOR_THEMES} from '@/services/vuestic-ui/themes'

export async function ProcessaResponse(response:any) {
    if (response?.messages && Array.isArray(response.messages)){
        response.messages.forEach((m: string) =>{
            Swal.fire({
                title: m,
                toast: true,
                background:  response.success ? COLOR_THEMES[0].colors.success :  COLOR_THEMES[0].colors.danger,
                color: '#fff',
                timer: 8000,
                showConfirmButton: false,
                timerProgressBar: true,
                showCloseButton: true,
                allowOutsideClick: true,
                allowEscapeKey: true,
                allowEnterKey: true,
                position: 'top-end',
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
        })
    }
    return response;
}
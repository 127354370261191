<template>
  <div class="app-layout">
    <navbar @change-side-bar="onCloseSidebarButtonClick" :is-sidebar-minimized="isSidebarMinimized"/>
    <div class="app-layout__content">
      <div class="app-layout__sidebar-wrapper" :class="{ minimized: this.isSidebarMinimized }">
        <div v-if="isFullScreenSidebar" class="d-flex justify--end">
          <va-button
            class="px-4 py-4 material-icons-outlined"
            icon="description"
            flat 
            color="dark"
          />
        </div>
        <sidebar
          :width="sidebarWidth"
          :minimized="isSidebarMinimized" 
          :minimizedWidth="sidebarMinimizedWidth"
        />
      </div>
      <div class="app-layout__page">
        <div class="app-layout__page__title">
          <h2 class="display-2">{{$route.meta.name ?? $route.name}}</h2>
        </div>
        <div class="layout fluid gutter--xl">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { onBeforeRouteUpdate } from 'vue-router';
import Sidebar from '@/components/sidebar/SidebarAdmin.vue';
import Navbar from '@/components/navbar/Navbar.vue';


export default {
  name: 'app-layout',
  components: {
    Navbar, Sidebar
  },
  methods: {
    onCloseSidebarButtonClick() {
      this.isSidebarMinimized = !this.isSidebarMinimized;
    }
  },
  data(){
    return {
      isSidebarMinimized: false
    }
  },
  setup() {
    const mobileBreakPointPX = 640
    const tabletBreakPointPX = 768

    const sidebarWidth = ref('16rem')
    const sidebarMinimizedWidth = ref(undefined)
    
    const isMobile = ref(false)
    const isTablet = ref(false)
    const checkIsTablet = () => window.innerWidth <= tabletBreakPointPX
    const checkIsMobile = () => window.innerWidth <= mobileBreakPointPX

    const onResize = () => {

      isMobile.value = checkIsMobile()
      isTablet.value = checkIsTablet()
      sidebarMinimizedWidth.value = isMobile.value ? 0 : '4rem'
      sidebarWidth.value = isTablet.value ? '100%' : '16rem'
    }

    onMounted(() => {
      window.addEventListener('resize', onResize)     
    })

    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize)
    })

    onBeforeRouteUpdate(() => {
      console.log('todo')
    })

    onResize()

    const isFullScreenSidebar = computed(() => isTablet.value && false)

    return {
      sidebarWidth, sidebarMinimizedWidth, 
      isFullScreenSidebar
    }
  }
}
</script>

<style lang="scss">
$mobileBreakPointPX: 640px;
$tabletBreakPointPX: 768px;

.app-layout {
  height: 100vh;
  display: flex;
  flex-direction: column;
  &__navbar {
    min-height: 4rem;
  }

  &__content {
    display: flex;
    height: calc(100vh - 4rem);
    flex: 1;

    @media screen and (max-width: $tabletBreakPointPX) {
      height: calc(100vh - 6.5rem);
    }

    .app-layout__sidebar-wrapper {
      position: relative;
      height: 100%;
      background: var(--va-white);
      box-shadow: var(--va-box-shadow);

      @media screen and (max-width: $tabletBreakPointPX) {
        &:not(.minimized) {
          width: 100%;
          height: calc(100vh - 8.5rem);
          position: fixed;
          bottom: 0;
          z-index: 999; 
        }

        .va-sidebar:not(.va-sidebar--minimized) {
          // Z-index fix for preventing overflow for close button
          z-index: -1;
          .va-sidebar__menu {
            padding: 0;
          }
        }
      }
    }
  }
  &__page {
    flex-grow: 2;
    overflow-y: scroll;

    &__title {
      background-image: url('/img/background.jpg');
      background-size: cover;
      background-position: center center;
      h2 {
        padding: 100px 0 150px 100px;
        margin-bottom: -100px;
        background: linear-gradient(150deg, rgba(12, 57, 52, 0.8), rgba(12, 57, 52,0.7)) !important;
        color:white;
        text-transform: capitalize;
      }
    }
  }
}
</style>

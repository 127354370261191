import { VuesticPlugin } from 'vuestic-ui';
import { createApp } from 'vue'
import { createGtm } from 'vue-gtm'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import router from './router'
import vuesticGlobalConfig from './services/vuestic-ui/global-config'
import moment from 'moment'
import {Auth} from "@/controllers/Auth";

moment.locale('pt-br');

const i18nConfig = {
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  fallbackWarn: false,
  missingWarn: false,
  locale: 'br',
  warnHtmlMessage: false, // disable warning HTML in message
  fallbackLocale: 'en',
  messages: {
    br: require('@/i18n/br.json'),
    en: require('@/i18n/en.json')
  }
}

const app = createApp(App)
app.use(router)
if (process.env.VUE_APP_GTM_ENABLED === 'true') {
  const gtmConfig = {
    id: process.env.VUE_APP_GTM_KEY,
    debug: false,
    vueRouter: router,
  }
  // app.use(createGtm(gtmConfig))
}
app.use(createI18n(i18nConfig))
app.use(VuesticPlugin, vuesticGlobalConfig)
app.config.globalProperties.$Auth = new Auth()
app.mount('#app')

